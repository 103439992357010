import {EquipmentPerformance} from '@hconnect/common/types'
import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'

import {useAxios} from '../../shared/hooks/useApi'
import {TimeRange} from '../types'

export const QueryKey = 'equipment-performances'

export const useEquipmentPerformances = (
  params: TimeRange & {plantEntryPointVisible?: boolean},
  plantId: string,
  options?: UseQueryOptions<EquipmentPerformance[], AxiosError>
) => {
  const axiosInstance = useAxios()
  const path = `/shifthandover/${plantId}/equipmentPerformances`

  return useQuery<EquipmentPerformance[], AxiosError>(
    [QueryKey, params, plantId],
    async () => {
      const {data} = await axiosInstance.get<EquipmentPerformance[]>(path, {
        params
      })
      return data
    },
    options
  )
}
