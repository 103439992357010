export type TimeRange = {
  startDate: string
  endDate: string
}

export type TimeRangeWithOffset = {
  timeRange: TimeRange
  yearOffset?: number
  locationId?: string
  showYTM?: boolean
}
