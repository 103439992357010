import {
  Stoppage,
  User,
  RunningTime,
  StoppageKind,
  StoppageType,
  EquipmentData
} from '@hconnect/common/types'
import {
  appendEquipmentData,
  appendUser,
  attachmentFilesToFormData,
  getEventStoppageType,
  isStoppageTypeWithReason
} from '@hconnect/common/utils'
import {DateRange} from '@hconnect/uikit'
import CheckIcon from '@mui/icons-material/Check'
import {Box} from '@mui/material'
import {isArray} from 'lodash'
import React from 'react'

import {DurationInterval, IssueGroupingKey, StoppageCreate, StoppageEdit, TimeStep} from '../types'

import {SKY_BLUE} from './palette'

type StoppageActionEvent = StoppageEdit | StoppageCreate

export const getSalesImpactLabel = (hasSalesImpact?: boolean) =>
  hasSalesImpact ? (
    <Box component="span" display="flex" alignContent="center">
      <CheckIcon sx={{fontSize: 16, color: SKY_BLUE}} />
      Yes
    </Box>
  ) : (
    <>No</>
  )

export const generateStoppageDefaultData = ({
  runningTime,
  isLast,
  equipment,
  suggestedMainEquipment
}: {
  runningTime: RunningTime
  isLast: boolean
  equipment: EquipmentData
  suggestedMainEquipment?: EquipmentData
}): Partial<Stoppage> => {
  const stoppageType = getEventStoppageType(equipment)
  const stoppageTypeData = stoppageType
    ? {
        stoppageType,
        ...(isStoppageTypeWithReason(stoppageType) && {stoppageReason: undefined})
      }
    : {}

  return {
    stoppageStart: runningTime.beginTechnical,
    stoppageEnd: isLast ? undefined : runningTime.endTechnical,
    // HCP-42934 uncomment when management decides
    // costCurrency: defaultCurrency,
    pxTrendTag: equipment.id,
    pxTrendStoppage: runningTime,
    ...(suggestedMainEquipment ? {mainEquipment: suggestedMainEquipment} : {}),
    ...stoppageTypeData
  }
}

const appendPxTrendStoppage = (formData: FormData, pxTrendStoppage: RunningTime) => {
  for (const key in pxTrendStoppage) {
    formData.append(`pxTrendStoppage[${key}]`, pxTrendStoppage[key] as string)
  }
}

export const prepareStoppagePayload = async (
  event: StoppageActionEvent,
  skipKeys: string[] = []
): Promise<FormData> => {
  const formData = new FormData()
  const keys = Object.keys(event).filter((item) => skipKeys.indexOf(item) < 0)
  for (const key of keys) {
    const value = event[key]
    if (value) {
      switch (key) {
        case 'attachments':
        case 'newAttachments':
          await attachmentFilesToFormData(value, formData)
          break
        case 'attachmentsToRemove':
          value.map((url: string) => formData.append('attachmentsToRemove', url))
          break
        case 'equipment':
          appendEquipmentData(formData, value as EquipmentData)
          break
        case 'createdBy':
          appendUser(formData, value as User, 'createdBy')
          break
        case 'mainEquipment':
          appendEquipmentData(formData, value as EquipmentData, true)
          break
        case 'pxTrendStoppage':
          appendPxTrendStoppage(formData, value as RunningTime)
          break
        default:
          formData.append(key, value as string | Blob)
      }
    }
  }
  return formData
}

export type AggregatedStoppageCodePayload = DateRange<Date> & {
  timezoneOffset?: number
  kinds?: StoppageKind[]
  mainEquipmentNumbers?: string[]
  equipmentNumbers?: string[]
  stoppageCodes?: string[]
  stoppageType?: StoppageType[]
  durationInterval?: DurationInterval
}

export type StoppageTimePeriodStatistics = AggregatedStoppageCodePayload & {
  timeStep?: TimeStep
  groupBy?: IssueGroupingKey
}

export const prepareAggregatedStoppageCodePayload = (
  payload: StoppageTimePeriodStatistics
): Record<string, string> => {
  const params = {}

  for (const [key, value] of Object.entries(payload)) {
    if (isArray(value)) {
      value?.forEach((param, index) => {
        params[`${key}[${index}]`] = param
      })
    } else if (key === 'durationInterval' && value) {
      const durationInterval: DurationInterval = value
      for (const [subKey, subValue] of Object.entries(durationInterval)) {
        params[`${key}.${subKey}`] = subValue
      }
    } else {
      params[key] = value
    }
  }

  return params
}
