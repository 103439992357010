import {EquipmentData} from '@hconnect/common/types'
import {PageContainer} from '@hconnect/uikit/src/lib2'
import {
  localDateTimeRangeToTimeRangeParam,
  sanitizeLocalDateRange
} from '@hconnect/uikit/src/lib2/components/simpleDatePicker/dateRangePickerHelpers'
import {Grid} from '@mui/material'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath, useLocation, useNavigate, useParams} from 'react-router'

import {DataContentWrapper} from '../components/DataContentWrapper'
import {TopIssues} from '../components/downtime'
import {DowntimeFilters} from '../components/downtime/filters/Filters'
import {StoppageStatsTable} from '../components/downtime/StoppageStatsTable'
import {FAILURE_STOPPAGE, STOPPAGE_DURATION} from '../consts'
import {StatusPageHeader} from '../containers/StatusPageHeader'
import {
  calculateEndDate,
  getCombinedDuration,
  getDurationTypesFromUrl,
  getEquipmentsFromUrl,
  getFailureKindsFromUrl,
  getStoppageCodesFromUrl,
  getTimeStep,
  isUnavailable
} from '../helpers'
import {
  BaseAnalyticsEventName,
  useTrackDowntimeAnalyticsEvent
} from '../helpers/trackAnalyticsEvents'
import {usePlantStoppages} from '../hooks/usePlantStoppages'
import {useQueryParamValue} from '../hooks/useQueryParamValue'
import {useSortedStoppages} from '../hooks/useSortedStoppages'
import {useStoppageCodeStats} from '../hooks/useStoppageCodeStats'
import {useStoppagesStatsTimeRange} from '../hooks/useStoppagesStatsTimeRange'
import {useTimeRange} from '../hooks/useTimeRange'
import {useTimeRangeParam} from '../hooks/useTimeRangeParam'
import {getSystemTimezone} from '../hooks/useTimezone'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'
import {
  PLANT_DOWNTIME,
  PLANT_DOWNTIME_STOPPAGES,
  PLANT_DOWNTIME_STOPPAGES_DETAILS
} from '../routing'
import {DurationType, IssueGroupingKey, StoppageCode} from '../types'

type PathParameter = {
  plantId: string
}

export const DowntimeStoppagesStats = () => {
  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const timezone = getSystemTimezone()
  const {plantId} = useParams<PathParameter>()
  if (!plantId) throw new Error('Missing plantId prop')
  const {search} = useLocation()
  const searchParams = new URLSearchParams(search)
  const [, setTimeRange] = useTimeRangeParam()
  const navigate = useNavigate()
  const trackAnalyticsEvent = useTrackDowntimeAnalyticsEvent()

  const {data: stoppageConfig, isLoading, error, refetch} = usePlantStoppages(plantId)

  const [failureStoppage, setFailureStoppage] = useQueryParamValue<string>(FAILURE_STOPPAGE, {
    keepInLocalStorage: true
  })
  const [stoppageDuration, setStoppageDuration] = useQueryParamValue<string>(STOPPAGE_DURATION, {
    keepInLocalStorage: true
  })
  const [mainEquipments, setMainEquipments] = useState<EquipmentData[] | undefined>(
    getEquipmentsFromUrl(searchParams.get('mainEquipmentNumbers'))
  )
  const [equipmentNumbers, setEquipmentNumbers] = useState<EquipmentData[] | undefined>(
    getEquipmentsFromUrl(searchParams.get('equipmentNumbers'))
  )
  const [stoppageCodes, setStoppageCodes] = useState<string[] | undefined>(
    getStoppageCodesFromUrl(searchParams.get('stoppageCode'))
  )

  const path = useMemo(() => generatePath(PLANT_DOWNTIME_STOPPAGES, {plantId}), [plantId])

  const timeRange = useTimeRange()
  const timeStep = getTimeStep(timeRange)

  const selectedMainEquipmentIds = mainEquipments?.map((eq) => eq.id)
  const selectedEquipmentIds = equipmentNumbers?.map((eq) => eq.id)
  const selectedStoppages = getFailureKindsFromUrl(failureStoppage)
  const selectedDurations = getDurationTypesFromUrl(stoppageDuration)

  const {
    topIssuesSortingKey,
    setTopIssuesSortingKey,
    topIssuesGroupingKey,
    setTopIssuesGroupingKey,
    sortedStoppages,
    aggregatedStoppagesQuery
  } = useSortedStoppages({
    plantId,
    timeRange,
    selectedMainEquipments: selectedMainEquipmentIds,
    selectedStoppages,
    selectedStoppageCodes: stoppageCodes,
    ...(stoppageDuration && {
      stoppageType: ['kiln'],
      durationInterval: getCombinedDuration(getDurationTypesFromUrl(stoppageDuration))
    }),
    selectedEquipmentNumbers: selectedEquipmentIds,
    timeStep
  })
  const stoppageCodeStatsQuery = useStoppageCodeStats(plantId, {
    ...timeRange,
    groupBy: topIssuesGroupingKey,
    ...(selectedMainEquipmentIds && {selectedMainEquipments: selectedMainEquipmentIds}),
    ...(selectedEquipmentIds && {selectedEquipments: equipmentNumbers?.map((eq) => eq.id)}),
    ...(selectedStoppages && {selectedStoppages}),
    ...(stoppageCodes && {selectedStoppageCodes: stoppageCodes}),
    ...(selectedDurations && {
      stoppageType: ['kiln'],
      durationInterval: getCombinedDuration(selectedDurations)
    }),
    timeStep
  })
  const {setStoppagesStatsTimeRange} = useStoppagesStatsTimeRange()
  const setTimeRangeFilter = useCallback(
    (startDate: string) => {
      const start = new Date(startDate)
      const endDate = calculateEndDate(timeStep, start)
      const localDateString = localDateTimeRangeToTimeRangeParam([start, endDate], timezone)
      if (localDateString) {
        setTimeRange(localDateString)
      }
    },
    [setTimeRange, timeStep, timezone]
  )

  const renderContent = () => {
    return (
      <>
        <StatusPageHeader
          title={t(`${performancePrefix}.downtime.label.stoppages`)}
          backButtonProps={{
            target: generatePath(PLANT_DOWNTIME, {plantId}),
            targetName: t(`${performancePrefix}.downtime.actions.backToDowntime`)
          }}
          filterComponent={
            <DowntimeFilters
              failureState={{
                failureStoppage: getFailureKindsFromUrl(failureStoppage),
                setFailureStoppage: (failures) => setFailureStoppage(failures?.join(','))
              }}
              mainEquipmentState={{mainEquipments, setMainEquipments}}
              stoppageCodesState={{stoppageCodes, setStoppageCodes}}
              equipmentNumbersState={{equipmentNumbers, setEquipmentNumbers}}
              stoppageDurationState={{
                stoppageDuration: getDurationTypesFromUrl(stoppageDuration),
                setStoppageDuration: (durations?: DurationType[]) =>
                  setStoppageDuration(durations?.join(','))
              }}
              path={path}
            />
          }
        />
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <TopIssues
              timeRange={timeRange}
              plantId={plantId}
              stoppageConfig={stoppageConfig}
              title={t(`${performancePrefix}.downtime.label.downtimes`)}
              issueCount={topIssuesGroupingKey === IssueGroupingKey.TIME_INTERVAL ? undefined : 5}
              hideInfoIcon
              stoppageCodeStats={stoppageCodeStatsQuery.data || {}}
              showFramesLabelFromTimeRange
              onBarClick={(groupId?: string) => {
                if (groupId && !isUnavailable(groupId)) {
                  if (topIssuesGroupingKey === IssueGroupingKey.TIME_INTERVAL) {
                    setTimeRangeFilter(groupId)
                    setStoppagesStatsTimeRange(
                      localDateTimeRangeToTimeRangeParam(
                        sanitizeLocalDateRange({startDate: timeRange.from, endDate: timeRange.to}),
                        timezone
                      )
                    )
                  }

                  const pathToRedirect = generatePath(PLANT_DOWNTIME_STOPPAGES_DETAILS, {
                    groupType: topIssuesGroupingKey,
                    groupId,
                    plantId
                  })
                  trackAnalyticsEvent(BaseAnalyticsEventName.UserClicksOn2LevelStoppagesGraphBar, {
                    groupId,
                    group: topIssuesGroupingKey
                  })
                  navigate(pathToRedirect)
                }
              }}
              topIssuesSortingKey={topIssuesSortingKey}
              setTopIssuesSortingKey={setTopIssuesSortingKey}
              topIssuesGroupingKey={topIssuesGroupingKey}
              setTopIssuesGroupingKey={setTopIssuesGroupingKey}
              sortedStoppages={sortedStoppages}
              aggregatedStoppagesQuery={aggregatedStoppagesQuery}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <StoppageStatsTable
              stoppageCodeStatsQuery={stoppageCodeStatsQuery}
              stoppageConfig={stoppageConfig}
              topIssuesGroupingKey={topIssuesGroupingKey}
              sortedStoppages={sortedStoppages}
            />
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <PageContainer>
      <DataContentWrapper<StoppageCode[]>
        isLoading={isLoading}
        data={stoppageConfig}
        retryFunction={() => void refetch()}
        error={error}
        renderContent={renderContent}
      />
    </PageContainer>
  )
}
