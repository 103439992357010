import {DashboardLink} from '@hconnect/common/components/downtime/DashboardLink'
import {
  EquipmentData,
  EquipmentRunningTimes,
  EquipmentType,
  RunningTime
} from '@hconnect/common/types'
import {getHoursMinutes} from '@hconnect/common/utils'
import {DateFormat} from '@hconnect/uikit'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {Box, Grid, MenuItem, TextField, Typography} from '@mui/material'
import {last} from 'lodash'
import moment from 'moment-timezone'
import React, {useMemo, useRef, FC, ReactNode, useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router'
import {generatePath, useNavigate, useLocation} from 'react-router-dom'

import {CementMillIcon} from '../assets/icons/CementMillIcon'
import {KilnRotaryIcon} from '../assets/icons/KilnRotaryIcon'
import {useTimeRange} from '../hooks/useTimeRange'
import {getSystemTimezone} from '../hooks/useTimezone'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'
import {STOPPAGE_ANALYSIS_STATUS} from '../routing'

import {StackedBarChart, TimeFormat} from './charts'

const Section: FC<{
  title: string
  value?: string | ReactNode
}> = ({title, value}) => (
  <Box>
    <Typography variant="caption">{title}</Typography>
    <Typography component="div" sx={{fontWeight: 500}}>
      {value || '-'}
    </Typography>
  </Box>
)

const KilnIcon = () => <KilnRotaryIcon sx={{marginRight: 1}} />

const MillIcon = () => <CementMillIcon sx={{transform: 'translateY(5px)', width: 24, height: 24}} />

const ICON_MAP: Record<EquipmentType, ReactNode> = {
  [EquipmentType.KILN]: <KilnIcon />,
  [EquipmentType.BALL_MILL]: <MillIcon />,
  [EquipmentType.DOSING_EQUIPMENT]: <MillIcon />,
  [EquipmentType.VERTICAL_MILL]: <MillIcon />,
  [EquipmentType.ROLLER_PRESS]: <MillIcon />,
  [EquipmentType.OTHER]: <MillIcon />
}

type PathParameter = {
  plantId: string
  equipmentId: string
}

type StoppageAnalysisGraphProps = {
  data: EquipmentRunningTimes[]
  equipmentIdToPreview?: string
  setEquipmentIdToPreview: (id: string | undefined) => void
  handleStoppageClick: (rt: RunningTime, isLast: boolean, equipment?: EquipmentData) => void
}

export const StoppageAnalysisGraph: FC<StoppageAnalysisGraphProps> = ({
  data,
  equipmentIdToPreview = '',
  setEquipmentIdToPreview,
  handleStoppageClick
}) => {
  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const {plantId, equipmentId} = useParams<PathParameter>()
  if (!plantId) throw new Error('Missing plantId prop')
  const navigate = useNavigate()
  const {from, to} = useTimeRange()
  const timezone = getSystemTimezone()
  const [dynamicLabelRef, setDynamicLabelRef] = useState<HTMLDivElement>()
  const endDateRef = useRef<HTMLDivElement>()
  const startDateRef = useRef<HTMLDivElement>()
  const {search} = useLocation()

  const equipments = useMemo<EquipmentData[]>(() => {
    const equipmentsData: EquipmentData[] = []

    data.forEach((equipmentsWithRunningTime: EquipmentRunningTimes) => {
      if (equipmentsWithRunningTime.equipment) {
        equipmentsData.push(equipmentsWithRunningTime.equipment)
      }
    })

    return equipmentsData
  }, [data])

  const equipmentWithRunningTimes = data.find((eq) => eq.equipment?.id === equipmentId)

  const timeRange = useMemo(
    () => ({
      startDate: moment(from.toJSON()),
      endDate: moment(to.toJSON())
    }),
    [from, to]
  )

  const isDateSame = moment(timeRange.startDate).isSame(timeRange.endDate, 'day')

  const handleSetDynamicLabelRef = useCallback((node: HTMLDivElement | null) => {
    if (node !== null) {
      setDynamicLabelRef(node)
    }
  }, [])

  const overflowsEndDate = useMemo(() => {
    const right = dynamicLabelRef?.getBoundingClientRect().right
    const left = endDateRef.current?.getBoundingClientRect().left
    if (right && left && data && timeRange) {
      return right > left
    }
    return true
  }, [data, timeRange, dynamicLabelRef, endDateRef])

  const overflowsStartDate = useMemo(() => {
    const left = dynamicLabelRef?.getBoundingClientRect().left
    const right = startDateRef.current?.getBoundingClientRect().right
    if (right && left && data && timeRange) {
      return right > left
    }
    return false
  }, [data, timeRange, dynamicLabelRef])

  const lastRunningTimeEndTime = useMemo(() => {
    if (overflowsStartDate || overflowsEndDate) {
      return last(equipmentWithRunningTimes?.runningTimes)?.end
    }
  }, [equipmentWithRunningTimes?.runningTimes, overflowsEndDate, overflowsStartDate])

  return (
    <CardBox>
      <Grid container spacing={3} alignItems="center">
        <Grid item sm={2} md={2}>
          <TextField
            fullWidth
            variant="filled"
            label={t(`${performancePrefix}.stoppageAnalysis.label.selectAsset`)}
            select
            data-test-id="select-asset"
            value={equipmentIdToPreview}
            onChange={(event) => {
              setEquipmentIdToPreview(event.target.value)
              navigate(
                generatePath(STOPPAGE_ANALYSIS_STATUS, {
                  plantId,
                  equipmentId: event.target.value
                }) + search
              )
            }}
          >
            {equipments.map((equipment) => (
              <MenuItem
                data-test-id={`action-status-dropdown-item-${equipment.id}`}
                key={equipment.id}
                value={equipment.id}
              >
                <Box display="flex" alignItems="center">
                  {equipment.equipmentType && ICON_MAP[equipment.equipmentType]}
                  {equipment.text}
                </Box>
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item sm={2} md={2}>
          <Section
            title={t(`${performancePrefix}.stoppageAnalysis.label.stoppageTime`)}
            value={`${getHoursMinutes(equipmentWithRunningTimes?.totalStopped)} h`}
          />
        </Grid>
        <Grid item sm={8} md={8} textAlign="right">
          <DashboardLink plantId={plantId} title={t(`${performancePrefix}.goToDashboard`)} />
        </Grid>
        <Grid item xs={12} data-test-id="stoppage-analysis-graph-label">
          {equipmentWithRunningTimes?.runningTimes && (
            <>
              <Box
                sx={{
                  flex: '1',
                  display: 'flex',
                  alignItems: 'stretch',
                  justifyContent: 'space-between',
                  borderRadius: 1,
                  overflow: 'hidden'
                }}
              >
                <Box display="flex" mb={1}>
                  <Box component={Typography} variant="caption" pr={1} ref={startDateRef}>
                    {!isDateSame && (
                      <>
                        <DateFormat date={timeRange.startDate.toISOString()} timezone={timezone} />{' '}
                        -{' '}
                      </>
                    )}
                    <TimeFormat date={timeRange.startDate.toISOString()} timezone={timezone} />
                  </Box>
                  {overflowsStartDate && lastRunningTimeEndTime && (
                    <Box component={Typography} fontWeight={600} fontSize={12} lineHeight="16px">
                      {t(`${performancePrefix}.stoppageAnalysis.label.now`)},{' '}
                      <TimeFormat date={lastRunningTimeEndTime} timezone={timezone} />
                    </Box>
                  )}
                </Box>
                <Box display="flex" mb={1}>
                  {overflowsEndDate && lastRunningTimeEndTime && (
                    <Box component={Typography} fontWeight={600} fontSize={12} lineHeight="16px">
                      {t(`${performancePrefix}.stoppageAnalysis.label.now`)},{' '}
                      <TimeFormat date={lastRunningTimeEndTime} timezone={timezone} />
                    </Box>
                  )}
                  <Box component={Typography} variant="caption" pl={1} ref={endDateRef}>
                    {!isDateSame && (
                      <>
                        <DateFormat date={timeRange.endDate.toISOString()} timezone={timezone} /> -{' '}
                      </>
                    )}
                    <TimeFormat date={timeRange.endDate.toISOString()} timezone={timezone} />
                  </Box>
                </Box>
              </Box>
              <StackedBarChart
                sx={{height: 48}}
                timeRange={timeRange}
                data={equipmentWithRunningTimes.runningTimes.filter((rt) => rt.begin !== rt.end)}
                handleStoppageClick={(rt, isLast) =>
                  handleStoppageClick(rt, isLast, equipmentWithRunningTimes?.equipment)
                }
                equipment={equipmentWithRunningTimes?.equipment}
                aboveBarComponent={(rt) => (
                  <Box
                    component={Typography}
                    fontWeight={600}
                    fontSize={12}
                    lineHeight="16px"
                    mt={-3}
                    ref={handleSetDynamicLabelRef}
                    sx={{transform: 'translateX(-50%)'}}
                    visibility={overflowsEndDate || overflowsStartDate ? 'hidden' : 'visible'}
                    position="absolute"
                  >
                    {t(`${performancePrefix}.stoppageAnalysis.label.now`)},{' '}
                    {rt.begin && <TimeFormat date={rt.begin} timezone={timezone} />}
                  </Box>
                )}
              />
            </>
          )}
        </Grid>
      </Grid>
    </CardBox>
  )
}
