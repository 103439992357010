import {useMainEquipmentByPxTrendTagSuggestion} from '@hconnect/common/services'
import {Stack, Typography, Button, Box, CircularProgress} from '@mui/material'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router'

import {useApi} from '../../../shared/hooks/useApi'
import {useStep} from '../../../shared/hooks/useStep'
import {generateStoppageDefaultData} from '../../helpers'
import {
  BaseAnalyticsEventName,
  useTrackDowntimeAnalyticsEvent
} from '../../helpers/trackAnalyticsEvents'
import {useTranslationPrefix} from '../../hooks/useTranslationPrefix'
import {RunningTimesOffPeriodsWithoutDetail} from '../../types'
import {StoppageCreate} from '../StoppageCreate'

import {AmendStoppagesVariantSwitch, StoppageSwitchVariant} from './AmendStoppagesVariantSwitch'
import {NoMoreStoppages} from './NoMoreStoppages'

type PathParameter = {
  plantId: string
}

type Props = {
  stoppages: RunningTimesOffPeriodsWithoutDetail[]
  onViewChange: (view: StoppageSwitchVariant) => void
  removeStoppage: (stoppage: RunningTimesOffPeriodsWithoutDetail) => void
}

export const AmendStoppagesNavigator = ({stoppages, onViewChange, removeStoppage}: Props) => {
  const {plantId} = useParams<PathParameter>()
  if (!plantId) throw new Error('Missing plantId prop')

  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const {axiosInstance} = useApi()
  const trackAnalyticsEvent = useTrackDowntimeAnalyticsEvent()

  const maxStep = stoppages.length
  const [
    currentStep,
    {canGoToNextStep, canGoToPrevStep, goToNextStep, goToPrevStep, reset: resetStep}
  ] = useStep(maxStep)
  const currentStoppageIndex = currentStep - 1
  const currentItem = stoppages.at(currentStoppageIndex)

  const handleGoToNextStep = useCallback(() => {
    goToNextStep()
    if (currentItem)
      trackAnalyticsEvent(BaseAnalyticsEventName.UserSkipStoppageWithMissingData, {
        equipmentId: currentItem.equipment.id
      })
  }, [currentItem, goToNextStep, trackAnalyticsEvent])

  const {data: suggestedMainEquipment, isLoading} = useMainEquipmentByPxTrendTagSuggestion(
    {
      plantId,
      pxTrendTag: currentItem?.equipment.id || '',
      axiosInstance
    },
    {
      enabled: !!currentItem?.equipment.id,
      onSuccess: (data) => {
        trackAnalyticsEvent(BaseAnalyticsEventName.UserOpenStoppageToAmendForm, {
          equipmentId: data.id
        })
      }
    }
  )

  const handleSubmitSuccess = (currentItem: RunningTimesOffPeriodsWithoutDetail) => {
    trackAnalyticsEvent(BaseAnalyticsEventName.UserAmendStoppage, {
      equipmentId: currentItem.equipment.id
    })
    if (stoppages.length && currentStoppageIndex === stoppages.length - 1) {
      resetStep()
    }
    removeStoppage(currentItem)
  }

  const closeNavigator = () => {
    onViewChange(null)
  }

  const initialData = useMemo(() => {
    if (!currentItem) return {}

    return generateStoppageDefaultData({
      runningTime: currentItem.runningTime,
      equipment: currentItem.equipment,
      isLast: false,
      suggestedMainEquipment
    })
  }, [currentItem, suggestedMainEquipment])

  if (isLoading) return <CircularProgress />

  if (!currentItem) {
    return <NoMoreStoppages onClose={closeNavigator} />
  }

  return (
    <Stack width="100%" sx={{height: '100%', overflowY: 'auto'}}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h3">
          {t(`${performancePrefix}.newStoppagesBanner.label.amendStoppages`)}
        </Typography>
        <AmendStoppagesVariantSwitch value="navigator" onChange={onViewChange} />
      </Stack>
      <Box>
        <StoppageCreate
          key={`${currentItem.equipment.id}-${currentItem.runningTime.beginTechnical}`}
          plantId={plantId}
          data-test-id="amend-stoppages-navigator"
          doClose={closeNavigator}
          initialData={initialData}
          onSuccessSubmit={() => handleSubmitSuccess(currentItem)}
          onError={(error) => {
            // If stoppage already exists, remove it from the list
            // 409 is the status code for conflict
            if (error.response?.status === 409) {
              removeStoppage(currentItem)
            }
          }}
          bodyWithPadding={false}
          renderFormFooterActions={({doSubmit, isSubmitDisabled, isDirty}) => (
            <NavigatorActions
              canGoToPrevStep={canGoToPrevStep}
              goToPrevStep={goToPrevStep}
              currentStep={currentStep}
              maxStep={maxStep}
              canGoToNextStep={canGoToNextStep}
              goToNextStep={handleGoToNextStep}
              handleSave={doSubmit}
              handleSaveAndNext={doSubmit}
              isDirty={isDirty}
              isSubmitDisabled={isSubmitDisabled}
            />
          )}
          withSalesImpact={false}
          withPriority
          withCategory
          eventTypeLabelPrefix={currentItem.equipment.text}
        />
      </Box>
    </Stack>
  )
}

type NavigatorActionsProps = {
  canGoToPrevStep: boolean
  goToPrevStep: () => void
  currentStep: number
  maxStep: number
  canGoToNextStep: boolean
  goToNextStep: () => void
  handleSave: () => void
  handleSaveAndNext: () => void
  isDirty: boolean
  isSubmitDisabled: boolean
}

const NavigatorActions: React.FC<NavigatorActionsProps> = ({
  canGoToPrevStep,
  goToPrevStep,
  currentStep,
  maxStep,
  isDirty,
  isSubmitDisabled,
  canGoToNextStep,
  handleSaveAndNext,
  handleSave,
  goToNextStep
}) => {
  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" useFlexGap>
      {canGoToPrevStep ? (
        <Button onClick={goToPrevStep} variant="text">
          {t(`${performancePrefix}.button.previous`)}
        </Button>
      ) : (
        <span style={{minWidth: 60}} />
      )}
      <Typography color="text.secondary">
        <span data-test-id="navigator-current-step-number">{currentStep}</span>
        {' / '}
        <span data-test-id="navigator-max-step-number">{maxStep}</span>
      </Typography>
      <NextButton
        isDirty={isDirty}
        isSubmitDisabled={isSubmitDisabled}
        canGoToNextStep={canGoToNextStep}
        handleSaveAndNext={handleSaveAndNext}
        handleSave={handleSave}
        goToNextStep={goToNextStep}
      />
    </Stack>
  )
}

type NextButtonProps = {
  isDirty: boolean
  isSubmitDisabled: boolean
  canGoToNextStep: boolean
  handleSaveAndNext: () => void
  handleSave: () => void
  goToNextStep: () => void
}

const NextButton: React.FC<NextButtonProps> = ({
  isDirty,
  isSubmitDisabled,
  canGoToNextStep,
  handleSaveAndNext,
  handleSave,
  goToNextStep
}) => {
  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()

  if (isDirty && canGoToNextStep) {
    return (
      <Button disabled={isSubmitDisabled} onClick={handleSaveAndNext} variant="text">
        {`${t(`${performancePrefix}.button.save`)} & ${t(`${performancePrefix}.button.next`)}`}
      </Button>
    )
  }

  if (isDirty) {
    return (
      <Button disabled={isSubmitDisabled} onClick={handleSave} variant="text">
        {t(`${performancePrefix}.button.save`)}
      </Button>
    )
  }

  if (canGoToNextStep) {
    return (
      <Button onClick={goToNextStep} variant="text">
        {t(`${performancePrefix}.button.skip`)}
      </Button>
    )
  }

  return <span style={{minWidth: 60}} />
}
