import {EquipmentData, RunningTime, Stoppage} from '@hconnect/common/types'
import {DateRange} from '@hconnect/uikit'
import moment from 'moment-timezone'
import React, {useCallback, FC, useState} from 'react'

import {generateStoppageDefaultData} from '../helpers'
import {useConfig} from '../hooks/configService'
import {usePlantTimeRange} from '../hooks/useTimeRange'
import {StoppageEvent} from '../types'

import {StackedBarChart} from './charts'
import {StoppageView} from './performance/StoppageView'
import {PlantRunningTimes} from './PlantRunningTimes'

type PlantRunningTimesCardProps = {
  plantId: string
}

export const PlantRunningTimesCard: FC<PlantRunningTimesCardProps> = ({plantId}) => {
  const {from, to}: DateRange<Date> = usePlantTimeRange({plantId})
  const {data: config} = useConfig(plantId)

  const [stoppageIdToPreview, setStoppageIdToPreview] = useState<string | undefined>(undefined)
  const [showCreateForm, setShowCreateForm] = useState<boolean>(false)
  const [stoppageToPreview, setStoppageToPreview] = useState<StoppageEvent | undefined>(undefined)
  const [stoppageData, setStoppageData] = useState<Partial<Stoppage> | undefined>(undefined)

  const handleClick = useCallback(
    (rt: RunningTime, isLast: boolean, equipment?: EquipmentData) => {
      if (rt.cockpitStoppageId) {
        setStoppageIdToPreview(rt.cockpitStoppageId)
      } else {
        setShowCreateForm(true)
        setStoppageToPreview(undefined)
        if (rt.begin && equipment && config?.defaultCurrency) {
          const defaultValue = generateStoppageDefaultData({
            runningTime: rt,
            isLast,
            equipment
          })
          setStoppageData(defaultValue)
        }
      }
    },
    [config?.defaultCurrency]
  )
  const getStackedBarChartComponent = useCallback(
    (equipment: EquipmentData, runningTimes: RunningTime[]) => {
      return (
        <StackedBarChart
          timeRange={{
            startDate: moment(from),
            endDate: moment(to)
          }}
          handleStoppageClick={(rt: RunningTime, isLast: boolean) =>
            handleClick(rt, isLast, equipment)
          }
          data={runningTimes}
          equipment={equipment}
        />
      )
    },
    [from, handleClick, to]
  )

  return (
    <>
      {stoppageIdToPreview || showCreateForm ? (
        <StoppageView
          plantId={plantId}
          showCreateForm={showCreateForm}
          stoppageData={stoppageData}
          stoppageToPreview={stoppageToPreview}
          setStoppageToPreview={setStoppageToPreview}
          setShowCreateForm={setShowCreateForm}
          stoppageIdToPreview={stoppageIdToPreview}
          setStoppageIdToPreview={setStoppageIdToPreview}
        />
      ) : (
        <PlantRunningTimes
          plantId={plantId}
          getStackedBarChartComponent={getStackedBarChartComponent}
        />
      )}
    </>
  )
}
