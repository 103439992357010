import {ContentBox} from '@hconnect/common/components/ContentBox'
import {EquipmentPerformanceResult} from '@hconnect/common/components/kpiPerformance/EquipmentPerformanceResult'
import {OperationHoursLegendTooltip} from '@hconnect/common/components/runningTimes'
import {ExpandedGroupIds, useExpandedGroups} from '@hconnect/common/hooks/useExpandGroups'
import {appUrl} from '@hconnect/common/hproduce/config'
import {COCKPIT_HOST} from '@hconnect/common/hproduce/config/cockpit'
import {SUMMARY_SEGMENT} from '@hconnect/common/hproduce/consts'
import {
  EquipmentData,
  EquipmentPerformance,
  EquipmentRunningTimes,
  ManualKpisProcessStage,
  RunningTime
} from '@hconnect/common/types'
import {checkIfKpiPerformanceIsAvailable, getUnmatchedEquipments} from '@hconnect/common/utils'
import {getTranslationKey} from '@hconnect/common/utils/translation.utils'
import {DateRange} from '@hconnect/uikit'
import {ExpandCollapseButton} from '@hconnect/uikit/src/lib2'
import {ChevronRight} from '@mui/icons-material'
import {Box, Link, Typography} from '@mui/material'
import {TFunction} from 'i18next'
import {get, isEmpty, last} from 'lodash'
import moment from 'moment-timezone'
import React, {FC, ReactNode, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath, useNavigate} from 'react-router-dom'

import {useConfig} from '../../hooks/configService'
import {useProcessStagesForManualKpis} from '../../hooks/kpi/useProcessStagesForManualKpis'
import {useEquipmentPerformances} from '../../hooks/useEquipmentPerformances'
import {useRunningTimes} from '../../hooks/useRunningTimes'
import {usePlantTimeRange} from '../../hooks/useTimeRange'
import {useTranslationPrefix} from '../../hooks/useTranslationPrefix'
import {STOPPAGE_ANALYSIS, STOPPAGE_ANALYSIS_STATUS} from '../../routing'
import {TimeRange} from '../../types'

import {ManualKpisView} from './ManualKpisView'

type GetActionsProps = {
  t: TFunction
  isManualKpisEnabled: boolean
  openProcessStages: string[]
  setOpenProcessStages: (id: ExpandedGroupIds) => void
  processStages: ManualKpisProcessStage[]
  equipmentIds: string[]
  expandedEquipments: string[]
  toggleExpandedEquipments: (id: ExpandedGroupIds) => void
  isKpiPerformanceAvailable: boolean
  plantId: string
  translationPrefix: string
}
const getActions = ({
  t,
  plantId,
  isManualKpisEnabled,
  isKpiPerformanceAvailable,
  toggleExpandedEquipments,
  equipmentIds,
  expandedEquipments,
  processStages,
  openProcessStages,
  setOpenProcessStages,
  translationPrefix
}: GetActionsProps) => {
  const linkPath = `${appUrl(COCKPIT_HOST)}${generatePath(`/:plantId/${SUMMARY_SEGMENT}`, {
    plantId
  })}`
  return (
    <Box display="flex" alignItems="center" gap={1.5}>
      {isManualKpisEnabled && (
        <ExpandCollapseButton
          variant="text"
          expanded={openProcessStages.length === processStages.length}
          onClick={() => setOpenProcessStages('all')}
          data-test-id="performance-expand-all-button"
          expandLabel={t(getTranslationKey('button.expandAll', translationPrefix))}
          collapseLabel={t(getTranslationKey('button.collapseAll', translationPrefix))}
        />
      )}
      {!isManualKpisEnabled && isKpiPerformanceAvailable && (
        <ExpandCollapseButton
          variant="text"
          expanded={expandedEquipments && expandedEquipments.length === equipmentIds.length}
          onClick={() => toggleExpandedEquipments('all')}
          data-test-id="performance-expand-all-button"
          expandLabel={t(getTranslationKey('button.expandAll', translationPrefix))}
          collapseLabel={t(getTranslationKey('button.collapseAll', translationPrefix))}
        />
      )}
      <Link
        href={linkPath}
        data-test-id="performance-section-link-to-cockpit"
        sx={(theme) => ({
          textDecoration: 'none',
          '&:hover': {
            '& .card-chevron, .card-name': {
              color: theme.palette.primary.main
            }
          }
        })}
      >
        <ChevronRight />
      </Link>
    </Box>
  )
}

type PerformanceSummaryProps = {
  plantId: string
  getStackedBarChartComponent: (equipment: EquipmentData, runningTimes: RunningTime[]) => ReactNode
}

export const PerformanceSummary: FC<PerformanceSummaryProps> = ({
  plantId,
  getStackedBarChartComponent
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const {data: config} = useConfig(plantId)
  const {from, to, quickSelectionId}: DateRange<Date> = usePlantTimeRange({plantId})
  const navigate = useNavigate()

  const timeRange: TimeRange = useMemo(
    () => ({startDate: from.toJSON(), endDate: to.toJSON()}),
    [from, to]
  )
  const isSingleDay = moment(from).isSame(to, 'day')
  const isTimeFrameSupported = isSingleDay || quickSelectionId === 'yesterday'
  const isManualKpisEnabled =
    !!config && (!config.pxTrendAvailable || !config.cceAvailable) && isTimeFrameSupported
  const {data: equipmentPerformances, isLoading: equipmentPerformancesLoading} =
    useEquipmentPerformances(timeRange, plantId)
  const {data: processStages = [], isLoading: isProcessStagesLoading} =
    useProcessStagesForManualKpis(language, plantId, {enabled: isManualKpisEnabled})
  const {data: runningTimePerformances, isLoading: isRunningTimePerformanceLoading} =
    useRunningTimes(timeRange, plantId)

  const equipmentIds: string[] =
    equipmentPerformances?.map((equipmentPerformance) => equipmentPerformance.equipment.id) || []
  const [expandedEquipments, toggleExpandedEquipments] = useExpandedGroups(
    equipmentIds,
    'summary-expanded-equipments'
  )
  const [openProcessStages, setOpenProcessStages] = useExpandedGroups(
    processStages.map(({code}) => code),
    'expanded-manual-kpis'
  )

  const isKpiPerformanceAvailable = checkIfKpiPerformanceIsAvailable(equipmentPerformances)
  const unmatchedEquipments = useMemo(
    () => getUnmatchedEquipments(equipmentPerformances, runningTimePerformances),
    [equipmentPerformances, runningTimePerformances]
  )

  const redirectToStoppageAnalysis = (equipmentId: string) => {
    navigate(
      generatePath(performance ? STOPPAGE_ANALYSIS : STOPPAGE_ANALYSIS_STATUS, {
        plantId,
        equipmentId
      })
    )
  }

  return (
    <ContentBox
      mode="max100PercentOfParentHeight"
      title={
        <Typography component="span" variant="h3" display="flex" alignItems="center">
          <>
            {t(getTranslationKey('performance.label.performance', performancePrefix))}
            {!isEmpty(equipmentPerformances) ||
              (!isEmpty(unmatchedEquipments) && (
                <OperationHoursLegendTooltip translationPrefix={performancePrefix} />
              ))}
          </>
        </Typography>
      }
      isLoading={
        isProcessStagesLoading || equipmentPerformancesLoading || isRunningTimePerformanceLoading
      }
      afterTitle={getActions({
        t,
        isManualKpisEnabled,
        openProcessStages,
        setOpenProcessStages,
        processStages,
        equipmentIds,
        expandedEquipments,
        toggleExpandedEquipments,
        isKpiPerformanceAvailable,
        plantId,
        translationPrefix: performancePrefix
      })}
      bodyWithPadding
      data-test-id="performance-section"
    >
      {equipmentPerformances?.map(
        (equipmentPerformanceData: EquipmentPerformance, index: number) => {
          const runningTimePerformance = runningTimePerformances?.find(
            ({equipment}) => equipment.matchingId === equipmentPerformanceData.equipment.matchingId
          )
          const activeStatus = get(last(runningTimePerformance?.runningTimes), 'runningTimeType')
          return (
            <EquipmentPerformanceResult
              translationPrefix={performancePrefix}
              {...equipmentPerformanceData}
              runningTimePerformance={runningTimePerformance}
              key={equipmentPerformanceData.equipment.id}
              isExpanded={expandedEquipments.includes(equipmentPerformanceData.equipment.id)}
              getStackedBarChartComponent={getStackedBarChartComponent}
              isLast={isEmpty(unmatchedEquipments) && equipmentPerformances?.length === index + 1}
              toggleExpandedEquipments={(
                id: ExpandedGroupIds,
                event: React.MouseEvent<SVGSVGElement, MouseEvent>
              ) => {
                event.stopPropagation()
                toggleExpandedEquipments(id)
              }}
              activeStatus={activeStatus}
              onClick={() => redirectToStoppageAnalysis(equipmentPerformanceData.equipment.id)}
            />
          )
        }
      )}
      {unmatchedEquipments?.map(
        (equipmentPerformanceData: EquipmentRunningTimes, index: number) => {
          const activeStatus = get(last(equipmentPerformanceData.runningTimes), 'runningTimeType')
          return (
            <EquipmentPerformanceResult
              translationPrefix={performancePrefix}
              runningTimePerformance={equipmentPerformanceData}
              equipment={equipmentPerformanceData.equipment}
              key={equipmentPerformanceData.equipment.id}
              getStackedBarChartComponent={getStackedBarChartComponent}
              isLast={unmatchedEquipments?.length === index + 1}
              expandButtonGap={!isEmpty(equipmentPerformances)}
              activeStatus={activeStatus}
              onClick={() => redirectToStoppageAnalysis(equipmentPerformanceData.equipment.id)}
            />
          )
        }
      )}
      {isManualKpisEnabled && (
        <ManualKpisView
          plantId={plantId}
          timeRange={timeRange}
          performancePrefix={performancePrefix}
          openProcessStages={openProcessStages}
          setOpenProcessStages={setOpenProcessStages}
          processStages={processStages}
          isProcessStagesLoading={isProcessStagesLoading}
        />
      )}
    </ContentBox>
  )
}
