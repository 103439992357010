import {ContentBox} from '@hconnect/common/components/ContentBox'
import {EquipmentPerformanceResult} from '@hconnect/common/components/kpiPerformance/EquipmentPerformanceResult'
import {ExpandedGroupIds, useExpandedGroups} from '@hconnect/common/hooks/useExpandGroups'
import {appUrl} from '@hconnect/common/hproduce/config'
import {COCKPIT_HOST} from '@hconnect/common/hproduce/config/cockpit'
import {SUMMARY_SEGMENT} from '@hconnect/common/hproduce/consts'
import {
  checkIfKpiPerformanceIsAvailable,
  filterEquipmentsWithKpiAssignments
} from '@hconnect/common/utils'
import {getTranslationKey} from '@hconnect/common/utils/translation.utils'
import {DateRange} from '@hconnect/uikit'
import {ExpandCollapseButton} from '@hconnect/uikit/src/lib2'
import {ChevronRight} from '@mui/icons-material'
import {Typography, Box, Link} from '@mui/material'
import React, {FC, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath} from 'react-router-dom'

import {useEquipmentPerformances} from '../../hooks/useEquipmentPerformances'
import {usePlantTimeRange} from '../../hooks/useTimeRange'
import {useTranslationPrefix} from '../../hooks/useTranslationPrefix'
import {TimeRange} from '../../types'

type PerformanceSummaryProps = {
  plantId: string
}

export const PlantEntryPointDailyKpiSummary: FC<PerformanceSummaryProps> = ({plantId}) => {
  const {t} = useTranslation()
  const {from, to}: DateRange<Date> = usePlantTimeRange({plantId})
  const {performancePrefix} = useTranslationPrefix()

  const timeRange: TimeRange = useMemo(
    () => ({startDate: from.toJSON(), endDate: to.toJSON()}),
    [from, to]
  )
  const {data: equipmentPerformances, isLoading} = useEquipmentPerformances(
    {...timeRange, plantEntryPointVisible: true},
    plantId
  )

  const equipmentPerformancesWithKpiAssignments = useMemo(
    () => filterEquipmentsWithKpiAssignments(equipmentPerformances),
    [equipmentPerformances]
  )

  const equipmentIds: string[] =
    equipmentPerformancesWithKpiAssignments?.map(
      (equipmentPerformance) => equipmentPerformance.equipment.id
    ) ?? []
  const [expandedEquipments, toggleExpandedEquipments] = useExpandedGroups(
    equipmentIds,
    'daily-kpi-expanded-equipments'
  )
  const isKpiPerformanceAvailable = checkIfKpiPerformanceIsAvailable(
    equipmentPerformancesWithKpiAssignments
  )

  return (
    <ContentBox
      mode="max100PercentOfParentHeight"
      title={
        <Typography component="span" variant="h3" display="flex" alignItems="center">
          {t(getTranslationKey('dailyKpiSummary.label.title', performancePrefix))}
        </Typography>
      }
      isLoading={isLoading}
      afterTitle={
        <TitleActions
          plantId={plantId}
          expandedEquipments={expandedEquipments}
          toggleExpandedEquipments={toggleExpandedEquipments}
          equipmentIds={equipmentIds}
          translationPrefix={performancePrefix}
          isKpiPerformanceAvailable={isKpiPerformanceAvailable}
        />
      }
      bodyWithPadding
      data-test-id="daily-kpi-summary"
    >
      {equipmentPerformancesWithKpiAssignments?.length === 0 ? (
        <Box px={2} py={4}>
          <Typography>
            {`${t(
              getTranslationKey('dailyKpiSummary.label.noKpisAreEnabled', performancePrefix)
            )}. ${t(getTranslationKey('dailyKpiSummary.label.kpiEnableNote', performancePrefix))}.`}
          </Typography>
        </Box>
      ) : (
        equipmentPerformancesWithKpiAssignments?.map((equipmentPerformanceData, index) => {
          return (
            <EquipmentPerformanceResult
              translationPrefix={performancePrefix}
              {...equipmentPerformanceData}
              key={equipmentPerformanceData.equipment.id}
              isExpanded={expandedEquipments.includes(equipmentPerformanceData.equipment.id)}
              isLast={equipmentPerformancesWithKpiAssignments?.length === index + 1}
              toggleExpandedEquipments={(id, event) => {
                event.stopPropagation()
                toggleExpandedEquipments(id)
              }}
              showKpiStatusIndicator
            />
          )
        })
      )}
    </ContentBox>
  )
}

type TitleActionsProps = {
  equipmentIds: string[]
  expandedEquipments: string[]
  toggleExpandedEquipments: (id: ExpandedGroupIds) => void
  isKpiPerformanceAvailable: boolean
  plantId: string
  translationPrefix: string
}

const TitleActions = ({
  plantId,
  isKpiPerformanceAvailable,
  toggleExpandedEquipments,
  equipmentIds,
  expandedEquipments,
  translationPrefix
}: TitleActionsProps) => {
  const {t} = useTranslation()
  const linkPath = `${appUrl(COCKPIT_HOST)}${generatePath(`/:plantId/${SUMMARY_SEGMENT}`, {
    plantId
  })}`

  return (
    <Box display="flex" alignItems="center" gap={1.5}>
      {isKpiPerformanceAvailable && (
        <ExpandCollapseButton
          variant="text"
          expanded={expandedEquipments && expandedEquipments.length === equipmentIds.length}
          onClick={() => toggleExpandedEquipments('all')}
          data-test-id="performance-expand-all-button"
          expandLabel={t(getTranslationKey('button.expandAll', translationPrefix))}
          collapseLabel={t(getTranslationKey('button.collapseAll', translationPrefix))}
        />
      )}
      <Link
        href={linkPath}
        data-test-id="performance-section-link-to-cockpit"
        sx={(theme) => ({
          textDecoration: 'none',
          '&:hover': {
            '& .card-chevron, .card-name': {
              color: theme.palette.primary.main
            }
          }
        })}
      >
        <ChevronRight />
      </Link>
    </Box>
  )
}
