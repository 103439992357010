import {
  fetchMainEquipmentByPxTrendTagSuggestion,
  getQueryKeyMainEquipmentByPxTrendTagSuggestion
} from '@hconnect/common/services'
import {EquipmentData, RunningTime, Stoppage} from '@hconnect/common/types'
import {DateRange} from '@hconnect/uikit'
import moment from 'moment-timezone'
import React, {useCallback, FC, useState} from 'react'
import {useQueryClient} from 'react-query'

import {useAxios} from '../../../shared/hooks/useApi'
import {generateStoppageDefaultData} from '../../helpers'
import {useConfig} from '../../hooks/configService'
import {useTimeRange} from '../../hooks/useTimeRange'
import {StoppageEvent} from '../../types'
import {StackedBarChart} from '../charts'

import {PerformanceSummary} from './PerformanceSummary'
import {StoppageView} from './StoppageView'

type PerformanceSectionProps = {
  plantId: string
}

export const PerformanceSection: FC<PerformanceSectionProps> = ({plantId}) => {
  const {from, to}: DateRange<Date> = useTimeRange()
  const {data: config} = useConfig(plantId)
  const queryClient = useQueryClient()
  const axiosInstance = useAxios()

  const [stoppageIdToPreview, setStoppageIdToPreview] = useState<string | undefined>(undefined)
  const [showCreateForm, setShowCreateForm] = useState<boolean>(false)
  const [stoppageToPreview, setStoppageToPreview] = useState<StoppageEvent | undefined>(undefined)
  const [stoppageData, setStoppageData] = useState<Partial<Stoppage> | undefined>(undefined)

  const handleClick = useCallback(
    async (rt: RunningTime, isLast: boolean, equipment?: EquipmentData) => {
      if (rt.cockpitStoppageId) {
        setStoppageIdToPreview(rt.cockpitStoppageId)
      } else {
        setStoppageToPreview(undefined)
        if (rt.begin && equipment && config?.defaultCurrency) {
          let suggestedMainEquipment: EquipmentData | undefined = undefined
          try {
            suggestedMainEquipment = await queryClient.fetchQuery({
              queryKey: getQueryKeyMainEquipmentByPxTrendTagSuggestion(plantId, equipment.id),
              queryFn: async () =>
                fetchMainEquipmentByPxTrendTagSuggestion({
                  plantId,
                  pxTrendTag: equipment.id,
                  axiosInstance
                }),
              staleTime: 1000 * 60 * 15
            })
          } catch (error) {
            console.error('Error fetching main equipment by px trend tag suggestion', error)
          }
          const defaultValue = generateStoppageDefaultData({
            runningTime: rt,
            isLast,
            equipment,
            suggestedMainEquipment
          })
          setStoppageData(defaultValue)
        }
        setShowCreateForm(true)
      }
    },
    [axiosInstance, config?.defaultCurrency, plantId, queryClient]
  )
  const getStackedBarChartComponent = useCallback(
    (equipment: EquipmentData, runningTimes: RunningTime[]) => {
      return (
        <StackedBarChart
          timeRange={{
            startDate: moment(from),
            endDate: moment(to)
          }}
          handleStoppageClick={(rt: RunningTime, isLast: boolean) =>
            handleClick(rt, isLast, equipment)
          }
          data={runningTimes}
          equipment={equipment}
        />
      )
    },
    [from, handleClick, to]
  )

  return (
    <>
      {stoppageIdToPreview || showCreateForm ? (
        <StoppageView
          plantId={plantId}
          showCreateForm={showCreateForm}
          stoppageData={stoppageData}
          stoppageToPreview={stoppageToPreview}
          setStoppageToPreview={setStoppageToPreview}
          setShowCreateForm={setShowCreateForm}
          stoppageIdToPreview={stoppageIdToPreview}
          setStoppageIdToPreview={setStoppageIdToPreview}
        />
      ) : (
        <PerformanceSummary
          plantId={plantId}
          getStackedBarChartComponent={getStackedBarChartComponent}
        />
      )}
    </>
  )
}
