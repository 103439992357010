import type {
  EquipmentCategory,
  EquipmentData,
  EquipmentType,
  RunningTime,
  ThinRunningTime
} from '@hconnect/common/types'

export type RunningTimesOffPeriodsWithoutDetail = {
  runningTime: ThinRunningTime
  equipment: EquipmentData
}

export type ThinEquipmentRunningTimes = {
  equipment: {
    category: EquipmentCategory
    id: string
    text: string
    type: EquipmentType
  }
  runningTimes: ThinRunningTime[]
}

export type PlantEquipmentRunningTimes = {
  equipment?: {
    matchingId: string
    id: string
    text: string
    type: EquipmentType
  }
  runningTimes?: RunningTime[]
  totalRunning: string
  totalStopped?: string
  productionVolumePerHour: number
  cementType?: string
}

export type EquipmentRunningTimesResult = {
  success: boolean
  errorMessage?: string
  plantId: string
  equipmentsWithRunningTimes: PlantEquipmentRunningTimes[]
}
