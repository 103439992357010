import {type Permission} from '@hconnect/apiclient'
import {NavItem} from '@hconnect/uikit/src/lib2'

import {useGetChecklistLinks} from '../../checklists/routing'
import {useGetPerformanceDashboardLinks} from '../../performanceDashboard/routing'

export const useGetLinks = ({permissions}: {permissions: Permission[]}): NavItem[] => {
  const performanceDashboardLinks = useGetPerformanceDashboardLinks()
  const checklistLinks = useGetChecklistLinks({permissions})

  return [...performanceDashboardLinks, ...checklistLinks]
}
