import {OperationHoursDrawer} from '@hconnect/common/components/runningTimes'
import {Stoppage, RunningTime, EquipmentRunningTimes, EquipmentData} from '@hconnect/common/types'
import {useNotification} from '@hconnect/uikit'
import {PageContainer} from '@hconnect/uikit/src/lib2'
import {Grid, Theme, useMediaQuery} from '@mui/material'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router'
import {generatePath} from 'react-router-dom'

import {DataContentWrapper} from '../components/DataContentWrapper'
import {DateTimeRangePicker} from '../components/DateTimeRangePicker'
import {StoppageAnalysisGraph} from '../components/StoppageAnalysisGraph'
import {StoppageAnalysisList} from '../components/StoppageAnalysisList'
import {StoppageAnalysisSideCard} from '../components/stoppageAnalysisSideCard'
import {StoppageCreate} from '../components/StoppageCreate'
import {StoppageDelete} from '../components/StoppageDelete'
import {StatusPageHeader} from '../containers/StatusPageHeader'
import {generateStoppageDefaultData} from '../helpers'
import {useConfig} from '../hooks/configService'
import {usePlantStatus} from '../hooks/usePlantStatus'
import {useRunningTimes} from '../hooks/useRunningTimes'
import {useTimeRange} from '../hooks/useTimeRange'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'
import {PLANT_KPI, PLANT_KPI_STATUS} from '../routing'
import {StoppageEvent, TimeRange} from '../types'

type PathParameter = {
  plantId: string
  equipmentId: string
}

type StoppageAnalysisProps = {
  performance?: boolean
}

export const StoppageAnalysis: React.FC<StoppageAnalysisProps> = ({performance}) => {
  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const {notify} = useNotification()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const [showCreateForm, setShowCreateForm] = useState<boolean>(false)
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState<boolean>(false)
  const [stoppageData, setStoppageData] = useState<Partial<Stoppage> | undefined>(undefined)
  const [equipmentIdToPreview, setEquipmentIdToPreview] = useState<string | undefined>('')
  const [stoppageToPreview, setStoppageToPreview] = useState<StoppageEvent | undefined>(undefined)
  const [stoppageIdToPreview, setStoppageIdToPreview] = useState<string | undefined>(undefined)
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [showDeleteDialog, setDeleteDialog] = useState<boolean>(false)
  const [stoppageClickData, setStoppageClickData] = useState<{
    runningTime: RunningTime
    isLast: boolean
    equipment?: EquipmentData
  }>()

  const {plantId, equipmentId} = useParams<PathParameter>()
  if (!plantId) throw new Error('Missing plantId prop')
  const {from, to} = useTimeRange()
  const dateRange: TimeRange = {startDate: from.toJSON(), endDate: to.toJSON()}

  const {data: config} = useConfig(plantId)
  const {data, isLoading, refetch, error} = useRunningTimes(dateRange, plantId, {
    onSuccess: (equipmentRunningTimes: EquipmentRunningTimes[]) => {
      if (equipmentRunningTimes) {
        const selectedEqAndRunningTimes = equipmentRunningTimes.find(
          (eq) => eq.equipment?.id === equipmentId
        )
        if (selectedEqAndRunningTimes?.equipment) {
          setEquipmentIdToPreview(selectedEqAndRunningTimes.equipment.id)
        }
      }
    }
  })
  const {data: plantData, isLoading: isLoadingPlantData} = usePlantStatus(plantId, dateRange, {
    onSuccess: (response) => {
      if (response.equipments) {
        const selectedEq = response.equipments.find((eq) => eq.id === equipmentId)
        if (selectedEq) {
          setEquipmentIdToPreview(selectedEq.id)
        }
      }
    }
  })

  const onSubmit = useCallback(() => {
    handleClose()
    notify('success', t(`${performancePrefix}.stoppageAnalysis.action.creationSuccess`))
  }, [notify, t, performancePrefix])

  const handleClose = () => {
    setShowCreateForm(false)
  }

  const handleMobileClick = useCallback(
    (rt: RunningTime, isLast: boolean, equipment?: EquipmentData) => {
      setStoppageClickData({runningTime: rt, isLast, equipment})
      setIsMobileDrawerOpen(true)
    },
    []
  )

  const handleSetEquipmentIdToPreview = useCallback((id?: string) => {
    setStoppageToPreview(undefined)
    setStoppageIdToPreview(undefined)
    setShowCreateForm(false)
    setEquipmentIdToPreview(id)
  }, [])

  const handleClick = useCallback(
    (rt: RunningTime, isLast: boolean, equipment?: EquipmentData) => {
      if (rt.cockpitStoppageId) {
        setStoppageIdToPreview(rt.cockpitStoppageId)
        setStoppageToPreview(undefined)
        setShowCreateForm(false)
      } else {
        setShowCreateForm(true)
        setStoppageToPreview(undefined)
        setStoppageIdToPreview(undefined)
        if (rt.begin && equipment && config?.defaultCurrency) {
          const defaultValue = generateStoppageDefaultData({
            runningTime: rt,
            isLast,
            equipment
          })
          setStoppageData(defaultValue)
        }
      }
    },
    [config?.defaultCurrency]
  )

  const onEditSuccess = useCallback(
    (stoppage: StoppageEvent) => {
      setIsEditMode(false)
      setStoppageToPreview(stoppage)
      notify('success', t(`${performancePrefix}.stoppageAnalysis.action.editionSuccess`))
    },
    [notify, t, performancePrefix]
  )

  const onDeleteSuccess = useCallback(() => {
    setStoppageToPreview(undefined)
    setDeleteDialog(false)
    notify('success', t(`${performancePrefix}.stoppageAnalysis.action.deleteSuccess`))
  }, [notify, t, performancePrefix])

  const setPreviewStoppage = (stoppage: StoppageEvent) => {
    setStoppageToPreview(stoppage)
    setStoppageIdToPreview(undefined)
    setShowCreateForm(false)
  }

  const stoppageId = stoppageToPreview?.id || stoppageIdToPreview

  const handlePreviewClose = () => {
    setStoppageToPreview(undefined)
    setStoppageIdToPreview(undefined)
  }

  const handleDelete = (stoppage: StoppageEvent) => {
    setDeleteDialog(true)
    setStoppageToPreview(stoppage)
  }

  const renderContent = () => (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {data && (
          <StoppageAnalysisGraph
            data={data}
            equipmentIdToPreview={equipmentIdToPreview}
            setEquipmentIdToPreview={handleSetEquipmentIdToPreview}
            handleStoppageClick={isMobile ? handleMobileClick : handleClick}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={8}>
        <StoppageAnalysisList
          setStoppageToPreview={setPreviewStoppage}
          stoppageToPreview={stoppageToPreview}
        />
      </Grid>
      {stoppageId && (
        <Grid item xs={12} sm={4}>
          <StoppageAnalysisSideCard
            isEditMode={isEditMode}
            onEdit={setIsEditMode}
            onDelete={handleDelete}
            stoppageId={stoppageId}
            onEditSuccess={onEditSuccess}
            plantId={plantId}
            onClose={handlePreviewClose}
          />
        </Grid>
      )}
      {showCreateForm && !stoppageId && (
        <Grid item xs={12} sm={4}>
          <StoppageCreate
            plantId={plantId}
            data-test-id="stoppage-analysis-graph-stoppage-create"
            doClose={handleClose}
            initialData={stoppageData}
            onSuccessSubmit={onSubmit}
          />
        </Grid>
      )}
    </Grid>
  )

  const renderMobileContent = () => (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {data && (
          <StoppageAnalysisGraph
            data={data}
            equipmentIdToPreview={equipmentIdToPreview}
            setEquipmentIdToPreview={handleSetEquipmentIdToPreview}
            handleStoppageClick={isMobile ? handleMobileClick : handleClick}
          />
        )}
      </Grid>
      {stoppageId ? (
        <Grid item xs={12} sm={4}>
          <StoppageAnalysisSideCard
            isEditMode={isEditMode}
            onEdit={setIsEditMode}
            onDelete={handleDelete}
            stoppageId={stoppageId}
            onEditSuccess={onEditSuccess}
            plantId={plantId}
            onClose={handlePreviewClose}
          />
        </Grid>
      ) : showCreateForm ? (
        <Grid item xs={12} sm={4}>
          <StoppageCreate
            plantId={plantId}
            data-test-id="stoppage-analysis-graph-stoppage-create"
            doClose={handleClose}
            initialData={stoppageData}
            onSuccessSubmit={onSubmit}
          />
        </Grid>
      ) : (
        <Grid item xs={12} sm={8}>
          <StoppageAnalysisList
            setStoppageToPreview={setPreviewStoppage}
            stoppageToPreview={stoppageToPreview}
          />
        </Grid>
      )}
    </Grid>
  )

  return (
    <PageContainer>
      <StatusPageHeader
        filterComponent={<DateTimeRangePicker type="all" />}
        title={t(`${performancePrefix}.stoppageAnalysis.label.title`)}
        backButtonProps={{
          targetName: t(`${performancePrefix}.stoppageAnalysis.label.backToPlant`, {
            target: plantData?.plant.name
          }),
          target: generatePath(performance ? PLANT_KPI : PLANT_KPI_STATUS, {plantId})
        }}
      />

      <DataContentWrapper<EquipmentRunningTimes>
        isLoading={isLoading || isLoadingPlantData}
        data={data}
        retryFunction={() => void refetch()}
        error={error}
        renderContent={isMobile ? renderMobileContent : renderContent}
      />

      {!!(showDeleteDialog && stoppageToPreview) && (
        <StoppageDelete
          plantId={plantId}
          onSuccess={onDeleteSuccess}
          setDialogOpen={setDeleteDialog}
          stoppage={stoppageToPreview}
          showDialog={true}
          translationPrefix={performancePrefix}
        />
      )}
      {isMobile && (
        <OperationHoursDrawer
          isOpen={isMobileDrawerOpen}
          onClose={() => setIsMobileDrawerOpen(false)}
          stoppageData={stoppageClickData}
          handleStoppageClick={handleClick}
          labels={{
            createIncident: t(`${performancePrefix}.stoppageAnalysis.label.createIncident`),
            editIncident: t(`${performancePrefix}.stoppageAnalysis.label.editIncident`),
            stoppage: t(`${performancePrefix}.stoppageAnalysis.label.stoppage`),
            running: t(`${performancePrefix}.stoppageAnalysis.label.running`)
          }}
        />
      )}
    </PageContainer>
  )
}
